import './ident.scss'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
function Ident(props) {
	const id = props.id
	let num = props.num
	if (!num) num = 5

	//console.log(props)
	return (
		<>
			<div className='row cases_row'>
				{(() => {
					let photos = []
					for (let i = 1; i <= num; i++) {
						photos.push(
							<div className='col-12 ident_wrapper' key={i}>
								<LazyLoadImage
									alt={'caseimg'}
									effect='blur'
									src={'img/cases/' + id + '/' + i + '.jpg'}
									placeholderSrc={
										'img/cases/' + id + '/placeholder-' + i + '.jpg'
									}
								/>
							</div>
						)
					}
					return photos
				})()}
			</div>
		</>
	)
}

export default Ident
