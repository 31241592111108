import './showcase.scss'

/*import { getServiceById } from '../../../services_'
import { getClientById } from '../../../clients_'
import { getCaseById } from '../../../cases_'*/
import Button from '../../Buttons/button'
import ButtonRofl from '../../Buttons/button-rofl'
import { useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Photo from '../../cards/photo'
import Smm from '../../cards/smm'
import Ident from '../../cards/ident'
import Motion from '../../cards/motion'
import Outdoor from '../../cards/outdoor'
import Web from '../../cards/web/web'

function Showcase() {
	const [cases, setCases] = useState([])
	const [clients, setClients] = useState([])
	const [services, setServices] = useState([])
	let [searchParams] = useSearchParams()
	let caseId = searchParams.get('id')
	const fetchUserData = () => {
		fetch(
			'https://api.airtable.com/v0/appn4B4L8tTVEasLi/clients', {
				headers: {Authorization: 'Bearer pat9IfKqrfFIj1KeI.99a4e9c7222133d22f1d79e7fbdfc34eb2d4038dc2c73c2a43ce571f063dd390'}
			  } 
		)
			.then(resp => resp.json())
			.then(data => {
				//console.log(data)
				//data.records.map(dat => console.log(dat.fields))
				setClients(data.records.map(dat => dat.fields))
			})
			.catch(err => {
				// Error
			})

		fetch(
			'https://api.airtable.com/v0/appn4B4L8tTVEasLi/services', {
				headers: {Authorization: 'Bearer pat9IfKqrfFIj1KeI.99a4e9c7222133d22f1d79e7fbdfc34eb2d4038dc2c73c2a43ce571f063dd390'}
			  } 
		)
			.then(resp => resp.json())
			.then(data => {
				//console.log(data)
				//data.records.map(dat => console.log(dat.fields))
				setServices(data.records.map(dat => dat.fields))
			})
			.catch(err => {
				// Error
			})
		fetch(
			'https://api.airtable.com/v0/appn4B4L8tTVEasLi/cases', {
				headers: {Authorization: 'Bearer pat9IfKqrfFIj1KeI.99a4e9c7222133d22f1d79e7fbdfc34eb2d4038dc2c73c2a43ce571f063dd390'}
			  } 
		)
			.then(resp => resp.json())
			.then(data => {
				//console.log(data)
				//data.records.map(dat => console.log(dat.fields))
				setCases(data.records.map(dat => dat.fields))
			})
			.catch(err => {
				// Error
			})
	}
	useEffect(() => {
		fetchUserData()
		window.scrollTo(0, 0)
		var url = 'showcase?id=' + caseId
		window.ym(90456550, 'hit', url)
		setTimeout(() => {
			window.QFormOrganizer._rebuildForms()
		}, 4000)
	}, [])

	function getCaseById(id) {
		return cases.find(item => item.id === parseInt(id))
	}
	function getServiceById(id) {
		return services.find(item => item.id == id)
	}
	function getClientById(id) {
		return clients.find(item => item.id == id)
	}

	const item = getCaseById(caseId)
	//console.log(item)
	let service = []
	let client = []
	let isPersonal = true
	if (item) {
		service = getServiceById(item.serviceid[0])
		client = getClientById(item.clientid[0])
		isPersonal = item.isPersonal
	}

	if (item && service && client)
		return (
			<>
				<div className='container-fluid about showcase_container'>
					{!isPersonal ? (
						<div className='bg'>
							<img
								src={'img/cases/' + item.id + '/bg.jpg'}
								alt=''
								className='d-none d-lg-block'
							/>
							<img
								src={'img/cases/' + item.id + '/bg_mob.jpg'}
								alt=''
								className='d-block d-lg-none'
							/>
						</div>
					) : (
						''
					)}
					<div className='container'>
						<div className='row'>
							<div className='showcase_row'>
								<div className='col-12'>
									<div className='showcase_header d-md-flex align-items-center'>
										{client.name}
										<span> / </span>
										<div className={service.class}>{service.btn}</div>
									</div>
								</div>
								<div className='col-12'>
									<div className='descr'>
										<div className='descr_text'>{service.description}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='container about '>
					{
						{
							1: <Web id={item.id} num={item.num} />,
							2: <Smm id={item.id} url={item.url} text={item.text} />,
							3: <Motion url={item.url} />,
							4: <Ident id={item.id} num={item.num} />,
							5: <Photo id={item.id} num={item.num} />,
							6: <Outdoor id={item.id} />,
						}[service.id]
					}

					<div className='row buttons_wrapper d-flex justify-content-center'>
						<div className='col-6 col-lg-4 '>
							<Button
								text='Вернуться в меню'
								url={'/cases?service=' + item.serviceid[0]}
								className='person'
							></Button>
						</div>

						<div className='col-6 col-lg-4 '>
							<button
								type='button'
								className='w-100'
								data-bs-toggle='modal'
								data-bs-target='#staticBackdrop'
							>
								<div className={'button button' + service.id}>
									Заказать услугу
								</div>
							</button>
						</div>
					</div>
					<ButtonRofl />
				</div>
				<div
					className='modal fade'
					id='staticBackdrop'
					data-bs-keyboard='false'
					aria-labelledby='staticBackdropLabel'
					aria-hidden='true'
				>
					<div className='modal-dialog modal-dialog-centered modal-dialog-scrollable'>
						<div className='modal-content'>
							<div className='modal-header'>
								<h5 className='modal-title'>
									Отправить
									<br />
									голубя
								</h5>
								<button
									type='button'
									className='close'
									data-bs-dismiss='modal'
									aria-label='Close'
								></button>
							</div>
							<div className='modal-body'>
								<div data-formid='form_rB1wQcy6CCZuKfmcMPlni_J9uHDifJNi'></div>
							</div>
						</div>
					</div>
				</div>
			</>
		)
}

export default Showcase
