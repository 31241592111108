import './app-footer.scss'

function AppFooter() {
	return (
		<>
			<div className='container_fluid mt-3 footer' id='anchor-contact'>
				<div className='container'>
					<div className='row footer_row justify-content-between align-items-center'>
						<div className='col-6 col-lg-3'>
							<div className='footer_item'>WV Studio</div>
						</div>

						<div className='col-6 col-md-2 col-lg-3  d-lg-flex d-flex justify-content-end align-items-center navbar_social'>
							<a
								className='navbar_social_link  footer_link'
								href='https://wa.me/79996812346'
								target='_blank'
								rel='noreferrer'
							>
								<div className='navbar_social_span'>
									<img
										className='navbar_social_img'
										src='/icon/whatsapp.png'
										alt='whatsapp'
									/>
								</div>
							</a>

							<a
								className='navbar_social_link footer_link'
								href='https://www.instagram.com/wv.smm/'
								target='_blank'
								rel='noreferrer'
							>
								<div className='navbar_social_span'>
									<img
										className='navbar_social_img'
										src='/icon/instagram.png'
										alt='insta'
									/>
								</div>
							</a>

							<a
								className='navbar_social_link  footer_link'
								href='https://t.me/ost_wv'
								target='_blank'
								rel='noreferrer'
							>
								<div className='navbar_social_span'>
									<img
										className='navbar_social_img'
										src='/icon/telegram.png'
										alt='telegram'
									/>
								</div>
							</a>
						</div>
					</div>
				</div>
			</div>
			<div className='container_fluid footer'>
				<div className='container'>
					<div className='row footer_row d-flex justify-content-between'>
						<div className='col-6 col-lg-3 footer_item'>
							<div className='footer_item_gray'>Прописка</div>
							<div>
								<a
									href='https://go.2gis.com/yxapjx'
									target='_blank'
									rel='noreferrer'
								>
									Калинина, 126
								</a>
							</div>
						</div>

						<div className='col-6 col-lg-3 footer_item'>
							<div className='footer_item_gray'>Связь</div>
							<div>
								<a href='tel:+79996812346'>+7 (999) 68-1234-6</a>
							</div>
						</div>

						<div className='col-6 col-lg-3 footer_item'>
							<div className='footer_item_gray'>Голубь</div>
							<div>
								<a href='mailto:mail@wv-studio.ru'>mail@wv-studio.ru</a>
							</div>
						</div>

						<div className='col-6 col-lg-3 footer_item'>
							<div className='footer_item_gray'>Паспорт</div>
							<div>1009 ******</div>
						</div>

						<div className='col-12'>
							<div className='footer_rofl_code d-flex justify-content-center'>
								Design: WV Studio &nbsp; &nbsp; &nbsp;Code:
								<a
									className='rofl_link'
									href='https://www.instagram.com/bye.webp/'
									target='_blank'
									rel='noreferrer'
								>
									@bye.webp
								</a>
							</div>
							<div className='footer_rofl_code d-flex justify-content-center'></div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default AppFooter
