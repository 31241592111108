import Button from '../../Buttons/button'
import './case.scss'

import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { Link } from 'react-router-dom'
function Case(props) {
	const item = props.case
	//console.log(item)
	//console.log(props)
	const clients = props.clients
	const services = props.services
	/*console.log(clients)
	console.log(services)*/
	//services.map(item => console.log(item))
	let client,
		service,
		isPersonal = null

	/*console.log(item.clientid + ' ololo' + item.serviceid)*/
	if (item.clientid && item.serviceid) {
		client = clients.find(it => it.id === item.clientid[0])
		service = services.find(it => it.id === item.serviceid[0])
		isPersonal = item.isPersonal
	}
	//console.log(client)
	//console.log(service)
	if (item && service && client)
		return (
			<>
				<div className='col-6 col-lg-4'>
					<div className='case d-flex flex-column justify-content-between'>
						<Link to={'/showcase?id=' + item.id}>
							<LazyLoadImage
								alt={'caseimg'}
								effect='blur'
								src={'/img/cases/' + item.id + '/main.jpg'}
								placeholderSrc={'img/cases/placeholder-main.jpg'}
							/>
						</Link>
						<div className='case_text'>
							{!isPersonal ? (
								<div>
									<div className='case_cname'>
										Компания: <span>{client.name}</span>
									</div>
									<div className='case_service'>
										Услуга: <span> {service.btn}</span>
									</div>{' '}
								</div>
							) : (
								<div>
									<div className='case_cname'>
										<span>{client.name}</span>
									</div>
									<div className='case_service'>
										Услуга: <span> {service.btn}</span>
									</div>
								</div>
							)}
						</div>
						<div className='case_button'>
							<Button
								text='Смотреть'
								url={'/showcase?id=' + item.id}
								className={service.id}
							/>
						</div>
					</div>
				</div>
			</>
		)
	//else console.log('check case N' + item.id)
}

export default Case
