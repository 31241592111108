import Button from '../../Buttons/button'
import './person.scss'

function Person(props) {
	const person = props.person
	//console.log(person)
	const name = person.name.split(' ')
	//console.log(name)
	return (
		<>
			<div className=''>
				<div className='person d-flex flex-column justify-content-between'>
					<div>
						<span className='person_main d-flex'>
							<span className='person_name'>
								{name[0]}
								<br />
								{name[1]}
								<p className='person_profession'>{person.profession}</p>
							</span>
							<img
								className='person_img'
								src={'img/persons/' + person.id + '.webp'}
								alt='person portrait'
							/>
						</span>

						<div className='person_descr'>{person.description}</div>
					</div>
					<Button
						text='Перейти в Instagram'
						url={person.url}
						className='person'
					/>
				</div>
			</div>
		</>
	)
}

export default Person
