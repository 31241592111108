import './outdoor.scss'

function Outdoor(props) {
	const id = props.id

	const dir = '/img/cases/' + id

	return (
		<>
			<div className='row cases_row'>
				<div className='col-12'>
					<div className='outdoor_wrapper'>
						<img
							className='outdoor_img'
							src={'img/cases/' + id + '/1.jpg'}
							alt=''
						/>
					</div>
				</div>

				<div className='col-8 col-lg-8'>
					<div className='outdoor_wrapper'>
						<img
							className='outdoor_img'
							src={'img/cases/' + id + '/2.jpg'}
							alt=''
						/>
					</div>
				</div>

				<div className='col-4 col-lg-4'>
					<div className='outdoor_wrapper'>
						<img
							className='outdoor_img'
							src={'img/cases/' + id + '/3.jpg'}
							alt=''
						/>
					</div>
				</div>
			</div>
		</>
	)
}

export default Outdoor
