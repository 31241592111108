import './photo.scss'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
function Photo(props) {
	const id = props.id
	let num = props.num
	if (!num) num = 5

	return (
		<>
			<div className='row cases_row d-flex justify-content-center'>
				{(() => {
					let photos = []
					for (let i = 1; i <= num; i++) {
						photos.push(
							<div className='col-12 col-lg-8' key={i}>
								<div className='photo_wrapper'>
									<LazyLoadImage
										alt={'caseimg'}
										effect='blur'
										src={'img/cases/' + id + '/' + i + '.jpg'}
										placeholderSrc={
											'img/cases/' + id + '/placeholder-' + i + '.jpg'
										}
									/>
								</div>
							</div>
						)
					}
					return photos
				})()}
			</div>
		</>
	)
}

export default Photo
