import './button-rofl.scss'

function ButtonRofl() {
	function handleClick(event) {
		window.scrollTo(0, 0)
	}
	return (
		<>
			<div className='col-12 align-self-end'>
				<div
					className='btn_rofl d-flex justify-content-center'
					onClick={() => handleClick()}
				>
					вжух ^-^
				</div>
			</div>
		</>
	)
}

export default ButtonRofl
