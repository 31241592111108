import { NavLink } from 'react-router-dom'
import { HashLink as Link } from 'react-router-hash-link'
import './app-header.scss'

function AppHeader() {
	//console.log(expanded)

	return (
		<header>
			<NavLink className='d-none d-md-block logo' to='/'>
				<img className='logo_img' src='/icon/wvlogo.png' alt='logo' />
			</NavLink>
			<div className='container'>
				<div className='row justify-content-between'>
					<div className='col-8 col-md-12 col-xl-12 col-xxl-10  order-2 order-md-1'>
						<nav className='navbar navbar-expand-md d-flex justify-content-end'>
							<button
								className='navbar-toggler hamburger collapsed'
								id='hamburger'
								type='button'
								data-bs-toggle='collapse'
								data-bs-target='#navbarSupportedContent'
								aria-controls='navbarSupportedContent'
								aria-expanded='false'
								aria-label='Toggle navigation'
							>
								<span className='line'></span>
								<span className='line'></span>
								<span className='line'></span>
							</button>
							<div
								className='collapse navbar-collapse'
								id='navbarSupportedContent'
							>
								<ul className='navbar-nav me-auto'>
									<li className='nav-item'>
										<NavLink
											className='nav-link ps-md-0'
											to='/'
											aria-current='page'
										>
											Главная
										</NavLink>
									</li>
									<li className='nav-item'>
										<NavLink
											className='nav-link'
											to='/about'
											aria-current='page'
										>
											О нас
										</NavLink>
									</li>
									{/* fix <li className='nav-item'>
										<a
											className='nav-link nav-service'
											href='/about#anchor-service'
											aria-current='page'
										>
											Услуги
										</a>
									</li>*/}
									<li className='nav-item'>
										<Link
											className='nav-link'
											to='/about#anchor-service'
											aria-current='page'
										>
											Услуги
										</Link>
									</li>
									<li className='nav-item'>
										<NavLink
											className='nav-link'
											to='/cases'
											aria-current='page'
										>
											Кейсы
										</NavLink>
									</li>
									{/*<li className='nav-item'  onClick={() => setExpanded(false)}>
										<NavLink
											className='nav-link'
											to='/reviews'
											aria-current='page'
										>
											Отзывы
										</NavLink>
									</li> */}

									<li className='nav-item'>
										<a
											className='nav-link nav-service'
											href='https://models.wv-studio.ru/'
											aria-current='page'
											target='_blank'
											rel='noreferrer'
										>
											Модели WV
										</a>
									</li>
									<li className='nav-item'>
										<a
											className='nav-link nav-service'
											href='https://content.wv-studio.ru/'
											aria-current='page'
											target='_blank'
											rel='noreferrer'
										>
											Клиентам WV
										</a>
									</li>
									<li className='nav-item'>
										<a
											className='nav-link nav-service'
											href='#anchor-contact'
											aria-current='page'
										>
											Контакты
										</a>
									</li>
								</ul>
							</div>
						</nav>
					</div>

					<div className='col-4 col-md-4 col-xxl-2 d-xs-flex d-sm-flex d-md-none d-xxl-flex d-flex justify-content-md-end align-items-center navbar_social order-1 order-md-2'>
						<a
							className='navbar_social_link'
							href='https://wa.me/79996812346'
							target='_blank'
							rel='noreferrer'
						>
							<div className='navbar_social_span'>
								<img
									className='navbar_social_img'
									src='/icon/whatsapp.png'
									alt='whatsapp'
								/>
							</div>
						</a>

						<a
							className='navbar_social_link'
							href='https://www.instagram.com/wv.smm/'
							target='_blank'
							rel='noreferrer'
						>
							<div className='navbar_social_span'>
								<img
									className='navbar_social_img'
									src='/icon/instagram.png'
									alt='instagram'
								/>
							</div>
						</a>

						<a
							className='navbar_social_link'
							href='https://t.me/ost_wv'
							target='_blank'
							rel='noreferrer'
						>
							<div className='navbar_social_span'>
								<img
									className='navbar_social_img'
									src='/icon/telegram.png'
									alt='telegram'
								/>
							</div>
						</a>
					</div>
				</div>
			</div>
		</header>
	)
}

export default AppHeader
