import './app.scss'
import AppHeader from './app-header'

import AppFooter from './app-footer'
import { Link, Outlet } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
function App() {
	return (
		<>
			<AppHeader />
			<div id='mainContainer' className='unblured'>
				<Outlet />
			</div>
			<AppFooter />
		</>
	)
}

export default App
