import './cases.scss'

import Button from '../../Buttons/button'
import ButtonRofl from '../../Buttons/button-rofl'
import Case from '../../cards/case'

/*import { getCases, filterCasesByService } from '../../../cases'
import { getServices } from '../../../services'*/
import { useSearchParams } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'
import { getTextById } from '../../../text'

function compareFn(a, b) {
	if (a.id > b.id) {
		return -1
	}
	if (a.id < b.id) {
		return 1
	}
	// a must be equal to b
	return 0
}

function Cases() {
	const [cases, setCases] = useState([])
	const [clients, setClients] = useState([])
	const [services, setServices] = useState([])
	const fetchUserData = () => {
		fetch(
			'https://api.airtable.com/v0/appn4B4L8tTVEasLi/clients', {
				headers: {Authorization: 'Bearer pat9IfKqrfFIj1KeI.99a4e9c7222133d22f1d79e7fbdfc34eb2d4038dc2c73c2a43ce571f063dd390'}
			  } 
		)
			.then(resp => resp.json())
			.then(data => {
				//console.log(data)
				//data.records.map(dat => console.log(dat.fields))
				setClients(data.records.map(dat => dat.fields))
			})
			.catch(err => {
				// Error
			})

		fetch(
			'https://api.airtable.com/v0/appn4B4L8tTVEasLi/services', {
				headers: {Authorization: 'Bearer pat9IfKqrfFIj1KeI.99a4e9c7222133d22f1d79e7fbdfc34eb2d4038dc2c73c2a43ce571f063dd390'}
			  } 
		)
			.then(resp => resp.json())
			.then(data => {
				//console.log(data)
				//data.records.map(dat => console.log(dat.fields))
				setServices(data.records.map(dat => dat.fields))
			})
			.catch(err => {
				// Error
			})
		fetch(
			'https://api.airtable.com/v0/appn4B4L8tTVEasLi/cases', {
				headers: {Authorization: 'Bearer pat9IfKqrfFIj1KeI.99a4e9c7222133d22f1d79e7fbdfc34eb2d4038dc2c73c2a43ce571f063dd390'}
			  } 
		)
			.then(resp => resp.json())
			.then(data => {
				//console.log(data)
				//data.records.map(dat => console.log(dat.fields))
				setCases(data.records.map(dat => dat.fields))
			})
			.catch(err => {
				// Error
			})
	}

	const [showMore, setShowMore] = useState(1)

	//const services = getServices()

	let [searchParams, setSearchParams] = useSearchParams()
	let serviceFilter = searchParams.get('service')

	useEffect(() => {
		fetchUserData()

		window.scrollTo(0, 0)
		var url = 'cases'
		if (serviceFilter) url += '?service=' + serviceFilter
		//if (serviceFilter)
		//setSearchParams('service=' + serviceFilter, { replace: false })
		window.ym(90456550, 'hit', url)
		/*setTimeout(() => {
			console.log(filteredCases)
		}, 4000)*/
	}, [])

	function filterCasesByService(service) {
		return cases.filter(item =>
			item.serviceid ? item.serviceid[0] === parseInt(service) : null
		)
	}
	cases.sort(compareFn)
	//console.log(cases)
	//console.log(serviceFilter)
	let filteredCases = []

	if (!serviceFilter) filteredCases = cases
	else filteredCases = filterCasesByService(serviceFilter)

	const casesSort = !serviceFilter ? cases : filteredCases

	const maxNumber = casesSort.length

	let isRenderButton = false
	if (maxNumber > showMore * 6) isRenderButton = true
	//console.log(filteredCases)
	function handleClick(id, event) {
		let search = searchParams.get('service')
		//console.log('click')
		setShowMore(1)
		if (!search) {
			search = {
				service: id,
			}
		} else if (id == search) {
			//console.log('lol')
			search = undefined
		} else {
			search = {
				service: id,
			}
		}

		setSearchParams(search, { replace: true })
	}

	return (
		<>
			<div className='container about'>
				<div className='row'>
					<div className='col-12'>
						<div className='header'>
							Кейсы<span> / cases</span>
						</div>
					</div>
				</div>

				<div className='row'>
					<div className='col-12'>
						<div className='descr'>
							<div className='descr_text'>{getTextById(4)}</div>
						</div>
					</div>
				</div>

				<div className='row cases_row'>
					{services.map(item => (
						<div
							className='col-6 col-lg-4 my-2 my-lg-2 px-2  px-lg-5'
							key={item.id}
							onClick={() => handleClick(item.id)}
						>
							<Button
								text={item.btn}
								url={item.url + `/?service=${item.id}`}
								className={item.id}
								service={item.id}
							/>
						</div>
					))}
				</div>

				<div className='row cases_show'>
					{casesSort.slice(0, 6 * showMore).map(item => (
						<Case
							case={item}
							key={item.id}
							clients={clients}
							services={services}
						/>
					))}
				</div>
				{isRenderButton ? (
					<div className='row'>
						<div className='col-xs-12 col-sm-6 col-lg-4 d-flex justify-content-center mx-auto mb-5 '>
							<button
								onClick={() => setShowMore(showMore + 1)}
								className='button button_more w-100'
							>
								Показать еще
							</button>
						</div>
					</div>
				) : (
					''
				)}
				<ButtonRofl />
			</div>
		</>
	)
}

export default Cases
