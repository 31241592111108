import './client.scss'

function Client(props) {
	const item = props.client

	return (
		<>
			<div className='col-3 col-lg-2 d-flex justify-content-center align-items-center '>
				<div className='client '>
					<img className='client_img' src={item.img} alt={item.name} />
				</div>
			</div>
		</>
	)
}

export default Client
