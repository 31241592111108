import './about.scss'

import Service from '../../cards/service'
import Client from '../../cards/client'
import ButtonRofl from '../../Buttons/button-rofl'
import Person from '../../cards/person'

//import { getPersons } from '../../../persons'
//import { getServices } from '../../../services'
/*import { getClients } from '../../../clients'*/
import { useEffect, useState } from 'react'
import { getTextById } from '../../../text'
import { Swiper, SwiperSlide } from 'swiper/react'
// import required modules
import { Pagination } from 'swiper'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'

function shuffle(array) {
	//
	var m = array.length,
		t,
		i

	// While there remain elements to shuffle…
	while (m) {
		// Pick a remaining element…
		i = Math.floor(Math.random() * m--)

		// And swap it with the current element.
		t = array[m]
		array[m] = array[i]
		array[i] = t
	}

	return array
}

function About() {
	const [persons, setPersons] = useState([])
	const [clients, setClients] = useState([])
	const [services, setServices] = useState([])
	const fetchUserData = () => {
		fetch(
			'https://api.airtable.com/v0/appn4B4L8tTVEasLi/persons', {
				headers: {Authorization: 'Bearer pat9IfKqrfFIj1KeI.99a4e9c7222133d22f1d79e7fbdfc34eb2d4038dc2c73c2a43ce571f063dd390'}
			  } 
		)
			.then(resp => resp.json())
			.then(data => {
				//console.log(data)
				//data.records.map(dat => console.log(dat.fields))
				setPersons(data.records.map(dat => dat.fields))
			})
			.catch(err => {
				// Error
			})

		fetch(
			'https://api.airtable.com/v0/appn4B4L8tTVEasLi/clients', {
				headers: {Authorization: 'Bearer pat9IfKqrfFIj1KeI.99a4e9c7222133d22f1d79e7fbdfc34eb2d4038dc2c73c2a43ce571f063dd390'}
			  } 
		)
			.then(resp => resp.json())
			.then(data => {
				//console.log(data)
				//data.records.map(dat => console.log(dat.fields))
				setClients(data.records.map(dat => dat.fields))
			})
			.catch(err => {
				// Error
			})

		fetch(
			'https://api.airtable.com/v0/appn4B4L8tTVEasLi/services', {
				headers: {Authorization: 'Bearer pat9IfKqrfFIj1KeI.99a4e9c7222133d22f1d79e7fbdfc34eb2d4038dc2c73c2a43ce571f063dd390'}
			  } 
		)
			.then(resp => resp.json())
			.then(data => {
				//console.log(data)
				//data.records.map(dat => console.log(dat.fields))
				setServices(data.records.map(dat => dat.fields))
			})
			.catch(err => {
				// Error
			})
	}

	//const persons = getPersons()
	//const services = getServices()
	/*const clients = getClients()*/
	useEffect(() => {
		window.scrollTo(0, 0)
		window.ym(90456550, 'hit', 'about')
		fetchUserData()
	}, [])
	//console.log(persons)
	const personsSort = persons
	//const personsSort = shuffle(persons)
	//console.log(clients)
	return (
		<>
			<div className='container about'>
				<div className='row'>
					<div className='col-12'>
						<div className='header'>
							WV Studio<span> / about</span>
						</div>
					</div>
				</div>

				<div className='row'>
					<div className='col-12'>
						<div className='descr'>
							<div className='descr_text'>{getTextById(1)}</div>

							<div className='descr_text mt-4'>{getTextById(2)}</div>
						</div>
					</div>
				</div>

				<div className='row'>
					<div className='col-12'>
						<div className='header'>
							Команда<span> / our team</span>
						</div>
					</div>
				</div>
				<div className='row px-3 px-lg-0'>
					<Swiper
						slidesPerView={1}
						spaceBetween={10}
						pagination={{
							clickable: true,
						}}
						breakpoints={{
							320: {
								slidesPerView: 1,
								spaceBetween: 12,
							},
							576: {
								slidesPerView: 2,
								spaceBetween: 20,
							},
							1200: {
								slidesPerView: 3,
								spaceBetween: 40,
							},
						}}
						modules={[Pagination]}
						className='mySwiper'
					>
						{personsSort.map(person => (
							<SwiperSlide key={person.id}>
								<Person person={person} />
							</SwiperSlide>
						))}
					</Swiper>
				</div>

				<div className='row'>
					<div className='col-12'>
						<div className='header' id='anchor-service'>
							Услуги<span> / services</span>
						</div>
					</div>
				</div>

				<div className='row'>
					<div className='col-12'>
						<div className='descr'>
							<div className='descr_text'>{getTextById(3)}</div>
						</div>
					</div>
				</div>

				<div className='row row_service'>
					{services.map(service => (
						<Service service={service} key={service.id} />
					))}
				</div>

				<div className='row'>
					<div className='col-12'>
						<div className='header header_clients'>
							Клиенты<span> / clients</span>
						</div>
					</div>
				</div>

				<div className='row row_clients'>
					{clients.map(client =>
						client.show ? <Client client={client} key={client.id} /> : ''
					)}
				</div>
				<div className='row mt-5'>
					<ButtonRofl />
				</div>
			</div>
		</>
	)
}

export default About
