import './motion.scss'

function Motion(props) {
	const url = props.url

	return (
		<>
			<div className='row cases_row'>
				<div className='col-12'>
					<div className='youtube d-flex justify-content-center'>
						<iframe
							src={url}
							title='anim'
							frameBorder='0'
							allow='accelerometer; autoplay; encrypted-media; gyroscope;'
							allowFullScreen
						></iframe>
					</div>
				</div>
			</div>
		</>
	)
}

export default Motion
