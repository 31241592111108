import Button from '../../Buttons/button'
import './service.scss'

function Service(props) {
	const service = props.service
	//fix url or class idk what to link
	return (
		<>
			<div className='col-12 col-md-6 col-lg-4'>
				<div className='service d-flex flex-column justify-content-between'>
					<div>
						<div className='service_name'>{service.name}</div>
						<div className='service_name_ru'>{service.nameru}</div>
						<div className='service_descr'>{service.description}</div>
					</div>

					<Button
						text='Подробнее'
						url={'/cases?service=' + service.id}
						className={service.id}
					/>
				</div>
			</div>
		</>
	)
}

export default Service
