import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import App from './components/app/'
import About from './components/Pages/about'
import Cases from './components/Pages/cases'
import Main from './components/Pages/main'
import Showcase from './components/Pages/showcase'
/*import reportWebVitals from './reportWebVitals'*/

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
	//<React.StrictMode>
	<BrowserRouter>
		<Routes>
			<Route path='/' element={<App />}>
				<Route path='' element={<Main />} />
				<Route path='about' element={<About />} />
				<Route path='cases' element={<Cases />} />
				<Route path='showcase' element={<Showcase />} />
				<Route path='*' element={<h1>404</h1>} />
			</Route>
		</Routes>
	</BrowserRouter>
	//</React.StrictMode>
)

/*
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();*/
