import './main.scss'
import Button from '../../Buttons/button'
import ButtonRofl from '../../Buttons/button-rofl'
import { useEffect } from 'react'
function Main() {
	useEffect(() => {
		window.scrollTo(0, 0)
		window.ym(90456550, 'hit', '')
	}, [])
	return (
		<>
			<div className='body_bg'>
				<div className='body_bg_noise'></div>
			</div>
			<div className='container container_btn'>
				<div className='row'>
					<div className='col-12 d-flex justify-content-center align-items-center mt-auto'>
						<Button
							text='Зачем медлить? Переходите сразу к кейсам.'
							url='/cases'
							className='main'
						/>
					</div>
					<ButtonRofl />
				</div>
			</div>
		</>
	)
}

export default Main
