let textList = [
	{
		text: 'WV Studio уже несколько лет на рынке digital-услуг в городе Благовещенске. За это время компания заработала статус надежного партнера, имеющего большой опыт работы в продвижении продуктов и сервисов коммерческих компаний.',
		id: 1,
	},
	{
		text: 'Мы используем современные подходы и тренды в дизайне, а наши сотрудники на постоянной основе обучаются у профессионалов и повышают свою квалификацию для того, чтобы компания не снижала планку качества оказываемых услуг.',
		id: 2,
	},
	{
		text: 'Наша компания специализируется на создании контента для социальных сетей, разработке сайтов, контекстной рекламе, а также 3D-визуализации и motion-дизайне, но и это не все. Мы делаем большой акцент на партнерстве с бизнесом, прорабатывая самые мельчайшие детали. В спектр наших услуг входит абсолютно все, что связано с продвижением, начиная с классического SMM и заканчивая консультациями по маркетингу и автоматизации бизнеса в целом.',
		id: 3,
	},
	{
		text: 'Мы прекрасно понимаем, что каждый бизнес индивидуален. Это касается и его потребностей. Для того, чтобы вы подобрали для себя оптимальный спектр услуг, мы подготовили удобный формат в виде кейсов. Вы можете выбрать подходящий кейс и дополнить услугами, не входящими в его стоимость.',
		id: 4,
	},
	{
		text: '',
		id: 5,
	},
	{
		text: '',
		id: 6,
	},
	{
		text: '',
		id: 7,
	},
	{
		text: '',
		id: 8,
	},
	{
		text: '',
		id: 9,
	},
	{
		text: '',
		id: 10,
	},
]

function getTextById(id) {
	return textList.find(item => item.id == id).text
}
function getTexts() {
	return textList
}

export { getTextById, getTexts }
