import React from 'react'

import { Link, useSearchParams } from 'react-router-dom'

import './button.scss'

function Button(props) {
	const url = props.url
	let [searchParams] = useSearchParams()
	let isActive = false

	let search = searchParams.get('service')
	if (typeof search === 'string') {
		isActive = Number.parseInt(search) === props.service
	}

	const btnClass =
		'button' + (props.className ? ' button_' + props.className : 'button_main')

	const checkExternal = () => {
		if (url == '') return <div className={btnClass}>{props.text}</div>
		if (url.startsWith('https://') || url.startsWith('http://'))
			return (
				<a href={url} alt='Профиль' target='_blank' rel='noopener noreferrer'>
					<div className={btnClass}>{props.text}</div>
				</a>
			)
		else if (!!props.service)
			return (
				<div className={btnClass + (isActive ? ' button_active' : '')}>
					{props.text}
				</div>
			)
		else
			return (
				<Link to={url}>
					<div className={btnClass + (isActive ? ' button_active' : '')}>
						{props.text}
					</div>
				</Link>
			)
	}

	return <div className='button_wrapper'>{checkExternal()}</div>
}

export default Button
