import './smm.scss'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
function Smm(props) {
	const id = props.id
	const url = props.url
	const text = props.text
	//console.log(props)
	return (
		<>
			<div className='row smm_row'>
				<div className='col-3 col-lg-3'>
					<div className='smm_logo d-flex align-items-center'>
						<img src={'img/cases/' + id + '/logo.png'} alt='smm_logo' />
					</div>
				</div>
				<div className='col-9 col-lg-6 d-flex align-items-center justify-content-lg-center'>
					<div className='smm_name'>
						{url}
						<div>{text}</div>
					</div>
				</div>
				<div className='col-12 col-lg-3  d-flex align-items-center justify-content-lg-end justify-content-center'>
					<div className='smm_button w-100'>
						<a
							href={'https://www.instagram.com/' + url + '/'}
							target='_blank'
							rel='noopener noreferrer'
						>
							<div className='smm_button_wrapper'>Подписаться</div>
						</a>
					</div>
				</div>
			</div>
			<div className='row smm_row smm_row_photo'>
				{(() => {
					let photos = []
					for (let i = 1; i <= 15; i++) {
						photos.push(
							<div className='col-4 smm_col' key={i}>
								<div className='smm_wrapper'>
									<LazyLoadImage
										alt={'caseimg'}
										effect='blur'
										src={'img/cases/' + id + '/' + i + '.jpg'}
										placeholderSrc={
											'img/cases/' + id + '/placeholder-' + i + '.jpg'
										}
									/>
								</div>
							</div>
						)
					}
					return photos
				})()}
			</div>
		</>
	)
}

export default Smm
